import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import ContactForm from '../../components/ContactForm';
import Reveal from 'react-reveal/Reveal';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact" />
    <div className="intro intro-small">
      <div className="container pb-8">
        
        <h1 className="title-1 text-center mb-6">Let's Connect</h1>
        <hr className="section-divider mb-6"/>
        <div className="row">
          <div className="col-md-6 wr-contact-info">
            <h2 className="title-3 text-primary mb-2">Contact Information</h2>
            <ul className="contact-info mb-6">
              <li>
                <i class="fas fa-map-marker-alt" />
                <p>38 North Canal Road,<br/> Singapore 059294</p>
              </li>
              {/* <li>
                <i class="fas fa-phone" />
                <a href="/">(02) 5550 4839</a> 
              </li> */}
              <li>
                <i class="fas fa-envelope" />
                <a href="mailto:eratnayake@lilardiacapital.com?Subject=Lilardia%20Capital%20More%20Info">eratnayake@lilardiacapital.com</a>
              </li>
            </ul>
            <h2 className="title-3 text-primary mb-2">Follow Us</h2>
            <ul className="social mb-6">
              <li>
                <a href="https://www.linkedin.com/company/lilardia-capital/">
                  <i class="fab fa-linkedin-in" />
                </a>
              </li>
              {/* <li>
                <a href="/">
                  <i class="fab fa-facebook" />
                </a>
              </li> */}
              {/* <li>
                <a href="/">
                  <i class="fab fa-twitter" />
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-6">
            <h2 className="title-3 text-primary mb-3">One-on-one communication with our clients is at the heart of who we are</h2>
            <Reveal effect="floatUp" delay={1200} duration={4000}>
              <div className="circle small op-6" style={{top: '30%', left: -20}}></div>
            </Reveal>
            <Reveal effect="floatUp" delay={200} duration={2000}>
              <div className="circle big op-1" style={{top: '40%', left: -60}}></div>
            </Reveal>
            <ContactForm />
          </div>
        </div>
        <Reveal effect="floatUp" duration={2000} delay={600}>
          <div className="circle big op-8" style={{top: '30%', left: -160}}></div>
        </Reveal>
        <Reveal effect="floatUp" duration={3000}>
          <div className="circle medium op-3" style={{top: '20%', right: -50}}></div>
        </Reveal>
      </div>
    </div>
    
  </Layout>
);

export default Contact;
