import React from 'react'
import { Form, Field } from 'react-final-form'

export default class ContactForm extends React.Component {
    state = {
        formSuccess: false,
        formFail: false,
    }

    onSubmit = async values => {
        // window.alert(JSON.stringify(values, 0, 2));
        return fetch('https://ahz6nhjiu4.execute-api.ap-southeast-1.amazonaws.com/default/email-broker', { 
            mode: 'no-cors',
            method: 'POST', 
            body: JSON.stringify(values), 
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((res) => {
            this.setState({ formSuccess: true });
        }).catch((err)=>{ 
            console.log(err)
            this.setState({ formFail: true });
        })
    }

    render() {
        return (
            <Form
                onSubmit={this.onSubmit}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                    errors.name = "Required";
                    }
                    if (!values.from) {
                    errors.from = "Required";
                    } 
                    // else if () {
                    //     errors.from = "Valid Email Required";
                    // }
                    if (!values.subject) {
                    errors.subject = "Required";
                    }
                    if (!values.message) {
                    errors.message = "Required";
                    }
                    return errors;
                }}
                initialValues={{ to: 'eratnayake@lilardiacapital.com' }}
                render={({ handleSubmit, reset, form, submitting, pristine, values }) => (
                    <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="wr-form">
                        <div className="form-group">
                            {/* <label>First Name</label> */}
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div>
                                    <label className="control-label"><div>Name</div></label>
                                    <input {...input} type="text" className="form-control" />
                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className="form-group">
                            <div className="form-group">
                                <Field name="from">
                                    {({ input, meta }) => (
                                        <div>
                                        <label className="control-label"><div>Email</div></label>
                                        <input {...input} type="email" className="form-control" />
                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className="form-group">
                            <Field name="subject">
                                {({ input, meta }) => (
                                    <div>
                                    <label className="control-label"><div>Subject</div></label>
                                    <input {...input} type="text" className="form-control" />
                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className="form-group">
                            <Field name="message">
                                {({ input, meta }) => (
                                    <div>
                                    <label className="control-label"><div>Your Message</div></label>
                                    <textarea {...input} className="form-control" />
                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="to">
                                {({ input, meta }) => (
                                    <div>
                                    <input {...input} type="hidden" />
                                    </div>
                                )}
                            </Field>
                            {/* <Field name="message" className="form-control" component="textarea" placeholder="Message" /> */}
                        </div>
                        <div className="submit-button buttons text-center text-md-left">
                            <button className="btn btn-primary" type="submit" disabled={submitting}>
                            Send Message
                            </button>
                        </div>  
                    </div>
                    <div className={this.state.formSuccess ? 'form-msg success show' : 'form-msg success'}>
                        <span>Thanks for your message! We'll be in touch with you shortly.</span>
                    </div>
                    <div className={this.state.formFail ? 'form-msg fail show' : 'form-msg fail'}>
                        <span>There was a problem sending your message. Please try again.</span>
                    </div>
                    </form>
                )}
            />
          )
    }
}